export enum ElectronicTagSortBy {
  NameAscending = 0,
  NameDescending = 1,
  CodeAscending = 2,
  CodeDescending = 3,
  FormatAscending = 4,
  FormatDescending = 5,
  DescriptionAscending = 6,
  DescriptionDescending = 7,
  ActiveAscending = 8,
  ActiveDescending = 9
}
