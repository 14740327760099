import {
  HttpClient,
  HttpParams,
  HttpParamsOptions
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ElectronicTagDto,
  ElectronicTagCreateDto,
  ElectronicTagUpdateDto,
  ElectronicTagListRequestDto,
  ElectronicTagListResultDto, ElectronicTagToggleActiveDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ElectronicTagsManagementService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getList(
    request: ElectronicTagListRequestDto
  ): Observable<ElectronicTagListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        Filter: request.filter ?? '',
        SortBy: request.sortBy ?? 'name'
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<ElectronicTagListResultDto>(
      `${this.apiUrl}/Enterprise/ElectronicTags/SelectList`,
      options
    );
  }

  toggleActiveStatus(electronicTagToggleActiveDto: ElectronicTagToggleActiveDto): Observable<ElectronicTagDto> {
    return this.http.put<ElectronicTagDto>(
      `${this.apiUrl}/Enterprise/ElectronicTags/ToggleActiveStatus/`,
      electronicTagToggleActiveDto
    );
  }

  create(
    electronicTagCreateDto: ElectronicTagCreateDto
  ): Observable<ElectronicTagDto> {
    return this.http.post<ElectronicTagDto>(
      `${this.apiUrl}/Enterprise/ElectronicTags`,
      electronicTagCreateDto
    );
  }

  update(
    electronicTagUpdateDto: ElectronicTagUpdateDto
  ): Observable<ElectronicTagDto> {
    return this.http.put<ElectronicTagDto>(
      `${this.apiUrl}/Enterprise/ElectronicTags`,
      electronicTagUpdateDto
    );
  }

  delete(id: string): Observable<string> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        id
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.delete<string>(
      `${this.apiUrl}/Enterprise/ElectronicTags/Delete/`,
      options
    );
  }
}
