import { Injectable } from '@angular/core';
import { ReferenceDataService } from '@ifhms/common/angular/data-access/admin-api';
import { ContextService } from '@ifhms/common/angular/utils';
import { ReferenceSelectListType } from '@ifhms/models/admin';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { LotsActions } from './lots.actions';

@Injectable()
export class LotsEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LotsActions.get),
      withLatestFrom(this.contextService.appContext$),
      exhaustMap( ( [ action , appContext] )=> {
        return this.service.getOperationSelectListByType(
          action.levelId || appContext.levelId,
          ReferenceSelectListType.Lots
        ).pipe(
          map((items: SersiSelectListItem[]) => {
            if (!items) return LotsActions.error;
            return LotsActions.getSuccess({ items });
          }),
          catchError(() => of(LotsActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private service: ReferenceDataService,
    private contextService: ContextService
  ) {
  }
}
