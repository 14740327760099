import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, tap } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AdminPermissionsService } from '@ifhms/common/angular/data-access/admin-api';
import { EnterprisePermissionsService } from '@ifhms/admin/web/domain/permissions';
import { transformPermission } from '@common/angular/utils';
import { EnterprisePermissionsDto } from '@ifhms/models/admin';

import { EnterprisePermissionsActions } from './enterprise-permissions.actions';

@Injectable()
export class EnterprisePermissionsEffects {

  constructor(
    private readonly actions$: Actions,
    private adminPermissionsService: AdminPermissionsService,
    private permissionsService: EnterprisePermissionsService
  ) {}

  getEnterprisePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterprisePermissionsActions.getEnterprisePermissions),
      exhaustMap(() =>
        this.adminPermissionsService.getEnterprisePermissionsForUser().pipe(
          tap(permissions => this.setPermissions(permissions)),
          map((enterprisePermissions) => EnterprisePermissionsActions.getEnterprisePermissionsSuccess({ enterprisePermissions })),
          catchError(err => of(EnterprisePermissionsActions.error(err)))
        )
      )
    )
  );

  private setPermissions(permissions: EnterprisePermissionsDto): void {
    const flatPermissionsMap = transformPermission(permissions);
    this.permissionsService.setPermissions(flatPermissionsMap);
  }

}
