import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { EnterprisePermissionsService } from '../services';

@Injectable({ providedIn: 'root' })
export class EnterprisePermissionGuard {

  constructor(
    private enterprisePermissionsService: EnterprisePermissionsService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.enterprisePermissionsService.hasPermission(route.data?.['requiredPermission'])
      .pipe(
        map(hasPermission => {
          if (hasPermission) return true;
          return this.router.parseUrl('/');
        })
      );
  }

}
