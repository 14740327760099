import { del, get, set } from 'idb-keyval';
import { downloadFile } from './file-download.helper';

declare global {
  interface Window {
    showDirectoryPicker: (args: any) => Promise<FileSystemDirectoryHandle>;
  }
}

export async function saveFileAtCustomPath(
  file: any,
  fileSaveName: string,
  fileType: string,
  namespace = 'ah'
): Promise<void> {
  try {
    // const isMac = isMacOS();
    //
    // // Disable folder picker for Mac users
    // if (isMac) {
    //   downloadFile(file, fileSaveName, fileType);
    //   return;
    // }

    const directoryHandle = await getDirectoryHandle(namespace);
    const allowed = await verifyPermission(directoryHandle);

    if (allowed) {
      await writeFile(directoryHandle, file, fileSaveName, fileType);
      await persistDirectoryHandle(directoryHandle, namespace);
    } else {
      downloadFile(file, fileSaveName, fileType);
      await removeDirectoryHandle(namespace);
    }
  } catch {
    downloadFile(file, fileSaveName, fileType);
  }
}

/**
 * Requests and stores a directory handle with granted permission as soon as the user triggers a file-saving action.
 * This ensures that when the file is finally ready to be saved, the stored handle can be reused without prompting again.
 */
export async function initializeDirectorySelection(namespace: string): Promise<void> {
  try {
    const directoryHandle = await getDirectoryHandle(namespace);
    const allowed = await verifyPermission(directoryHandle);

    if (allowed) {
      // If permission is already granted, ensure the handle is persisted
      await persistDirectoryHandle(directoryHandle, namespace);
    } else {
      // If not allowed, remove old handle and try prompting the user again
      await removeDirectoryHandle(namespace);
      const newDirectoryHandle = await showDirectoryHandleDialog();
      await persistDirectoryHandle(newDirectoryHandle, namespace);
    }
  }
  catch (e) {
    console.warn('initialize directory selection error: ', e);
    return;
  }
}

async function getDirectoryHandle(namespace: string): Promise<any> {
  const storedHandle = await getStoredDirectoryHandle(namespace);
  // after browser restarts, updates, or storage changes
  // the handle retrieved from idb-keyval may no longer be functional as a FileSystemDirectoryHandle instance
  if (!storedHandle || typeof storedHandle.queryPermission !== 'function') {
    // The handle is no longer valid; show the directory picker again
    return showDirectoryHandleDialog();
  }
  return storedHandle;
}

export async function getStoredDirectoryHandle(namespace: string): Promise<any> {
  return await get(`${namespace}-directoryHandle`);
}

function showDirectoryHandleDialog(): Promise<FileSystemDirectoryHandle> | null {
  if (typeof window.showDirectoryPicker !== 'function') {
    return null;
  }
  return window.showDirectoryPicker({
    mode: 'readwrite'
  });
}

async function persistDirectoryHandle(directoryHandle: any, namespace: string): Promise<void> {
  if (!directoryHandle) return;
  return set(`${namespace}-directoryHandle`, directoryHandle);
}

export function removeDirectoryHandle(namespace: string): Promise<void> {
  return del(`${namespace}-directoryHandle`);
}

async function verifyPermission(
  directoryHandle: {
    getFileHandle: (arg0: string, arg1: { create: boolean }) => any;
    queryPermission: (args: any) => Promise<string>;
    requestPermission: (args: any) => Promise<string>;
  } | null
): Promise<boolean> {
  const options = {
    mode: 'readwrite'
  };

  if (!directoryHandle) return false;

  if ((await directoryHandle.queryPermission(options)) === 'granted') {
    return true;
  }
  return (await directoryHandle.requestPermission(options)) === 'granted';
}

async function writeFile(
  directoryHandle: {
    getFileHandle: (arg0: string, arg1: { create: boolean }) => any;
    queryPermission: (args: any) => Promise<string>;
    requestPermission: (args: any) => Promise<string>;
  },
  file: any,
  fileSaveName: string,
  fileType: string
): Promise<void> {
  const fileHandle = await directoryHandle.getFileHandle(fileSaveName, {
    create: true
  });
  const fileWriter = await fileHandle.createWritable();
  await fileWriter.write(new Blob([file], { type: fileType }));
  await fileWriter.close();
}
