export enum EnterpriseFeaturePermission {
  CompaniesEdit = 'canEditCompanies',
  UserManagementEdit = 'canEditUserManagement',
  ModuleAccessEdit = 'canEditModuleAccess',
  ReferenceDataEdit = 'canEditReferenceData',
  FrmDiagnosisSettingsEdit = 'canEditFrmDiagnosisSettings',
  FrmProductsEdit = 'canEditFrmProducts',
  FrmProceduresEdit = 'canEditFrmProcedures',
  FrmTreatmentProtocolsEdit = 'canEditFrmTreatmentProtocols',
  FrmAdgSettingsEdit = 'canEditFrmAdgSettings',
  ProcurementSettingsEdit = 'canEditProcurementSettings',
  ProcurementParameterEstimatesEdit = 'canEditProcurementParameterEstimates',
  ProcurementCodesEdit = 'canEditProcurementCodes',
  PSMTSettingsEdit = 'canEditPsmtSettings',
  DSORTSettingsEdit = 'canEditDsortSettings',
  ReleaseNotesView = 'canReadReleaseNotes'
}
