import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { ReferenceDataCountryService } from '@ifhms/common/angular/data-access/admin-api';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';

import { CountriesActions } from './countries.actions';

@Injectable()
export class CountriesEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesActions.get),
      exhaustMap(() => this.service.get().pipe(
        map((items: SelectListItemWithTranslationDto[]) => {
          if (!items) return CountriesActions.error;
          return CountriesActions.getSuccess({ items });
        }),
        catchError(() => of(CountriesActions.error()))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private service: ReferenceDataCountryService
  ) {
  }
}
