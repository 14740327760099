import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { ReferenceDataUnitsOfMeasureService } from '@ifhms/common/angular/data-access/admin-api';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { ContextService } from '@ifhms/common/angular/utils';

import { UnitsOfMeasureActions } from './units-of-measure.actions';

@Injectable()
export class UnitsOfMeasureEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitsOfMeasureActions.get),
      exhaustMap(() => {
        return this.unitsOfMeasureService.get().pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) return UnitsOfMeasureActions.error;
            return UnitsOfMeasureActions.getSuccess({ items });
          }),
          catchError(() => of(UnitsOfMeasureActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private unitsOfMeasureService: ReferenceDataUnitsOfMeasureService
  ) {
  }
}
